import axios from "axios"
import config from "./config"

const customAxios = axios.create({
  withCredentials: true,
  baseURL: config.BASE_URL,
  headers: {
    Accept: "application/json",
  },
})
customAxios.interceptors.request.use(config => {
  const csfr = (document.querySelector("meta[name=csrf-token]") as any)?.content
  if (csfr) {
    config.headers["X-CSRF-Token"] = csfr
  }
  return config
})
customAxios.interceptors.request.use(config => {
  const query = new URLSearchParams(window.location.search)
  const locale = query.get("locale")
  if (locale) {
    config.headers["Locale"] = locale
  }
  return config
})
;(customAxios as any).isCancel = axios.isCancel
;(customAxios as any).CancelToken = axios.CancelToken

export default customAxios
